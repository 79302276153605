import { datadogRum } from '@datadog/browser-rum';

import { User } from '@/lib/apollo/types/gen/graphql';

const isEnabled = import.meta.env.VITE_DATADOG_ENABLE === '1';

export const initDatadog = (): void => {
  if (!isEnabled) {
    return;
  }

  datadogRum.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    site: 'datadoghq.com',
    service: 'frontend_v2',
    env: import.meta.env.MODE,
    version: process.env.BUILD_VERSION, // vite.config.ts でセット
    sampleRate: Number(import.meta.env.VITE_DATADOG_SAMPLE_RATE),
    replaySampleRate: Number(import.meta.env.VITE_DATADOG_REPLAY_SAMPLE_RATE),
    trackInteractions: true,
    silentMultipleInit: true,
    allowedTracingOrigins: Array.from(
      new Set([
        import.meta.env.VITE_API_BASE_URL,
        import.meta.env.VITE_GQL_BASE_URL,
      ]),
    ),
  });
  datadogRum.startSessionReplayRecording();
};

export const setDatadogUser = (userId?: User['userId']): void => {
  if (!isEnabled) {
    return;
  }

  if (userId) {
    datadogRum.setUser({
      id: userId,
    });
  } else {
    datadogRum.removeUser();
  }
};
